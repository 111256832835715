export default defineI18nConfig(() => ({
    legacy: false,
    // datetimeFormats,
    locale: 'en',
    globalInjection: true,
    inheritLocale: true,
    fallbackLocale: 'nl',
    missingWarn: false,
    fallbackWarn: false
}));
